.modal-content {
    border-radius: 4px;
    border: 1px solid #e9ecef;
}

.modal-header {
    padding: 1.5rem 2rem 1.5rem 2rem;
    background-color: rgba(239, 238, 234, .5);
}

.modal-body {
    max-height: 75vh;
    overflow-y: auto;
    padding: 2rem;
}

.modal-footer {
    background-color: rgba(239, 238, 234, .2);
}

.menu-section {
    background-color: #FAFAFA;
    border-radius: 4px;
}

.section-type:hover,
.active-section-type {
    background-color: #5897fb;
    color: white;
    cursor: pointer;
}

@media (min-width: 576px) {
    .modal-dialog {
        margin: 0;
    }
}

.modal-dialog {
    margin: 0;
}

.modal-body {
    max-height: 79vh;
}

.card-quantity-pill {
    background-color: #dc3545;
    width: 25px;
    position: absolute;
    left: -13px;
    top: -13px;
    color: white;
    border-radius: 50%;
    text-align: center;
}

.card-item-img-lg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 277px;
    border-radius: 4px;
}

.modal-footer {
    background-color: rgba(239, 238, 234, .2);
    min-height: 73px;
}

label.form-check-label.pl-3 {
    display: inline-block;
    margin: 0;
    font-family: 'futura-pt';
    margin-left: 0px;
    padding-left: 0px !important;
}

label.form-check-label.fooditem-popup.pl-3 {
    padding-left: 1rem !important;
}

.edit-contact-popup {
    margin: 0 auto;
}