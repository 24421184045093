body {
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
    height: 100%;
    min-height: 100%;

}

#root {
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    align-items: flex-start !important;
}

.login-alert {
    width: 75%;
    margin: 20px auto;
}

.login_banner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.logo {
    height: 130px;
}

.signup_subtext {
    width: 80%;
    margin: auto;
    margin-top: 20px;
}

.form-signin {
    width: 80%;
    padding: 15px;
    margin: auto;
    text-align: left;
}

.form-signin .checkbox {
    font-weight: 400;

}

.form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
}

.form-signin label {
    font-weight: 400;
    color: black;
}

.form-signin .form-control:focus {
    z-index: 2;
}

.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.btn-cuisine {
    border-radius: 24.2px;
    margin: 10px
}

.height-46 {
    height: 46px;
}

.copy-rights {
    margin-top: calc(100% - 20px);
}

.brand-sign-up-logo {
    margin: 0px !important;
}

.password-icon {
    position: absolute;
    cursor: pointer;
    right: 4%;
    top: 32%;
    height: 20px;
    width: 20px;
    z-index: 1000;
}
.custom-google-signin>div>div>div {
    padding: 25px;
}

.custom-google-signin button {
    width: 100%;
    height: 46px;
    border-radius: 8px;
    padding: 0 16px;
    background-color: #ffffff;
    border: 2px solid #4285f4;
    font-size: 16px;
    font-weight: 500;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  /* Hover and Focus States */
  .custom-google-signin button:hover,
  .custom-google-signin button:focus {
    background-color: #f1f1f1;
    border-color: #357ae8;
  }
  
  /* Icon Alignment */
  .custom-google-signin button .google-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  }
/*      
  .form-container {
    max-width: 400px;
    margin: 0 auto;
  } */
  .google-auto-signin {
    width: 395px !important;
  }
  

/* end  */