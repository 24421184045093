.main-acknowledge {
    margin: 0 auto;
    text-align: center;
    margin-top: 45px;
}

.main-acknowledge p {
    font-size: 25px;
    color: #4771eb;
}

.main-acknowledge span {
    line-height: 2.6em;
}