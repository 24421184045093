.menu-card {
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	transition: all 0.2s ease-in-out 0s;
	border: 1px solid rgba(0, 0, 0, .125);
}

div.menu-card:hover {
	/* border: 1px solid #4763fc; */
	cursor: pointer;
}

.shadow-sm {
	box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.card-title {
	width: 80%;
	letter-spacing: 0.015rem;
	font-weight: bold;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.card-price-tag {
	color: #343a40;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 0.875rem;
}

.card-text {
	color: #808080;
	font-size: 0.875rem;
	/* height: 2.8em; */
	/* overflow: hidden; */
	clear: both;
}

.card-item-img {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: 144px;
	-webkit-border-radius: 4px 4px 0 0;
	-moz-border-radius: 4px 4px 0 0;
	border-radius: 4px 4px 0 0;
}

.card .card-title {
	color: #222222;
	/* margin-bottom: 1.5rem; */
	/* text-transform: capitalize; */
	font-size: 1rem;
}

.card-text {
	color: #808080;
	font-size: 0.875rem;
	/*height: 4.8em;*/
	overflow: scroll;
	clear: both;
}

.menu-spinner {
	margin: 0 auto;
}

.vendor-menu-item-rating i {
	color: #d2d2d2;
}

.vendor-menu-item-rating {
	padding: 0px 10px 10px;
}

.main-stars {
	margin: 0px 10px 4px;
}

.star {
	clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
	width: 17px;
	height: 17px;
	background: lightgray;
	display: inline-block;
	position: relative;
}

.rateValue {
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 100%;
	background: #7571f8;
}

.main-menu-card {
	margin-bottom: 30px;
}

.section-name-setting {
	width: 100%;
}

.background-grey {
	background: #efefef;
}

/* Dropzone CSS starts  */

.editable-object:hover {
	background-color: rgb(230 237 243);
	border-radius: 4px;
}

.editable-object {
	cursor: pointer;
}

.editable-list-item {
	background-color: white;
	border: 0;
	border-bottom: 1px dotted #ced4da;
}

.editable-list-item:hover {
	background-color: rgb(230 237 243);
	border-radius: 4px;

}

.editable-list-item.active {
	background-color: rgb(230 237 243);
	border-radius: 4px;
	color: #007bff;
	border-bottom: 0;
}

.options-row {
	background-color: #FAFAFA;
	border-radius: 4px;
}

.row-hover-background:hover {
	background-color: transparent !important;
}

.td-hover-background:hover {
	background-color: #f3f3f9 !important;
}

.fooditem-image {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;
}

/* Dropzone CSS ends  */



/* FoodItem Preview CSS */
.fooditem-preview-btn {
	background: '#000';
	bottom: 37px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
	-webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
	-moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
	color: #fff;
	cursor: pointer;
	display: block;
	opacity: 1;
	outline: 0;
	position: fixed;
	left: 37px;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-transition: bottom .2s, opacity .2s;
	-o-transition: bottom .2s, opacity .2s;
	-moz-transition: bottom .2s, opacity .2s;
	transition: bottom .2s, opacity .2s;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	z-index: 1000
}

.progress-bar-size{
	width: 55px;
	height: 55px;
}

.item-page-skeleton{
	height: 90px
}

.tagging-text{
	color: #007bff;
}

.section-food-Item{
	max-Width: 400px;
	overflow: hidden;
	text-Overflow: ellipsis
}

.rateValue {
	background: #FCBA31;
}