.cursor-pointer {
	cursor: pointer;
}

.cursor-default {
	cursor: default;
}

.chowmill-logo {
	width: 36px !important;
	height: 50px !important;
}


.icon-user-setting {
	font-size: 22px;
}

.restaurant-contact {
	background: #f3f3f9;
	padding-top: 30px;
	border-radius: 4px;
	padding-bottom: 30px;
}

.restaurant-names:hover {
	background: #e6e9ed;
}

.restaurant-names-active {
	background: #e6e9ed;
}

.dropdown-option:hover {
	background: #f8f9fa;
}

.dropdown-option-active {
	background: #f8f9fa;
}

.profilelink {
	color: rgba(36, 28, 21, 0.65);
	font-size: .80rem;
	text-decoration: none;
}

.active-sidbar-item {
	background: #E6ECF1;
	color: #56595a;
}

.bold-sidbar-item span {
	color: #000000 !important;
}

.auth .brand-logo img {
	/* width: 150px; */
	height: 130px;
}

.option_select {
	border-bottom: 1px solid #e9ecef;
}

.dishes-serves-count {
	color: #a0a3a5;
	margin-left: 20px;
	margin-bottom: 0;
}

.chowmill-logo-full {
	text-align: center;
}

.brand-logo.brand-sign-up-logo.chowmill-logo-full.chowmill-logo-full img {
	height: 100px;
}

.help-required {
	color: #dc3545;
}

.align-del-btn {
	margin-left: 13px !important;
}

.breadcrumb.breadcrumb-custom .breadcrumb-item span {
	position: relative;
	color: inherit;
	border: 1px solid #dbe3e6;
	display: inline-block;
	vertical-align: top;
}

.breadcrumb.breadcrumb-custom .breadcrumb-item span:before {
	right: -22px;
	z-index: 3;
	border-left-color: #dbe3e6;
	border-left-style: solid;
	border-left-width: 12px;
}

.breadcrumb.breadcrumb-custom .breadcrumb-item span:before,
.breadcrumb.breadcrumb-custom .breadcrumb-item span:after {
	position: absolute;
	top: -9px;
	width: 0;
	height: 0;
	content: "";
	border-top: 21px solid transparent;
	border-bottom: 21px solid transparent;
}

.due-small-link {
	text-decoration: underline;
	color: #007bff;
}

.invoice-config button.btn.btn-primary-cmill {
	/* width: 185px; */
	height: 36px;
	line-height: 6px;
}

.config-search-daypicker {
	height: 36px;
	width: 68px;
	line-height: 4px;
}

.custom-switch label {
	line-height: 22px;
}

/* @media screen and (max-width: 1440px) {
 .food-item-popup{
	width: 62% !important;
	left: 20% !important;
	top: 10% !important;
 }
} */
@media screen and (max-width: 769px) {
	.invoice-config .daypicker-config {
		padding-left: 15px !important;
	}

	.rdrCalendarWrapper.rdrDateRangeWrapper {
		max-width: 100%;
	}

	.rdrDateRangePickerWrapper {
		display: block !important;
	}

	.rdrDateDisplayWrapper,
	.rdrMonthAndYearWrapper,
	.rdrMonth {
		width: 100% !important;
		margin: 0 auto;
	}

	.rdrDefinedRangesWrapper {
		max-width: 100%;
		margin: 0 auto;
		padding-left: 34px;
	}
}

.confirm-text-area-section {
	background-color: #FAFAFA;
}

.invisible-scroll {
	-ms-overflow-style: none;
	/* Internet Explorer 10+ */
	scrollbar-width: none;
	/* Firefox */
}

.invisible-scroll::-webkit-scrollbar {
	display: none;
	/* Safari and Chrome */
}
.menu-active {
	content: '';
    display: flex;
    justify-content: center;
    position: absolute;
    align-items: center;
    z-index: 2;
    left: 0px;
	top: 22px;
    font-size: 16px;
    background-color: white;
    color: #4763fc;
	margin: 0 24px 0 5px;
    font-weight: 600;
	padding-left: 10px;
    padding-bottom: 33px;
    border-bottom: 3px solid #4763fc;
    cursor: auto;
	cursor: pointer;
}
.menu-active:focus-within {
	color: #4763fc;
	font-weight: 600;
}
.menu-inactive {
	position: absolute;
	top: 22px;
	display: flex;
	justify-content: flex-start;
	font-size: 16px;
	padding-left: 15px;
	cursor: pointer;
}
.menu-inactive::after {
	border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 0;
    font: normal normal normal 24px/1 "Material Design Icons";
    content: "\F140";
    width: auto;
    height: auto;
    vertical-align: middle;
    line-height: 5px;
    font-size: 1.25rem;
    margin-left: 0;
}
.upgrade-bs-class>a::after {
	content: '' !important;
}
.upgrade-bs-class {
	line-height: 1px;
}
.menu-dropdown {
	padding: 16px;
	font-size: 16px;
	color :rgba(36,28,21,.65)!important;
	border-bottom: 1px solid #ced4da;
	cursor: pointer;
}
.menu-dropdown:hover {
	background-color: #f8f9fa;
}
.menu-dropdown-container {
	padding: 0;
	top: 45px !important;
}
.menu-link:hover {
	text-decoration: none;
}
.menu-link {
	color: rgba(36, 28, 21, 0.65) !important;
}

@media (max-width: 991px){
.upgrade-bs-class > a {
    margin-bottom: 30px;
}
.upgrade-bs-class > a > p {
	padding-bottom: 15px;
}
}
.food-item-popup{
    position: fixed;
    z-index: 1101;
    left: 25%;
    top: 12%;
    width: 50%;
    right: 12%;
    background-color: white;
    border-radius: 10px;
}
.edit-option-popup{
	max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.buffet-description{
	color: var(--gray-light);
}
.buffet-header-content{
	font-size: 20px !important;
	font-weight: 600 !important;
	color: #212529 !important;
	line-height: 34px;
}
.buffet-toggle > .form-check-input {
	height: 1.375em;
	width: 2.635em;
}
.buffet-toggle{
	min-height: 1.7rem;
	margin: 0;
}
@media only screen and (max-width: 480px){
	.buffet_dishsizes_add_area,
	.menu_list_add_button{
	  height: 190px !important;
	}
}
.buffet_dishsizes_add_area,
.menu_list_add_button{
  padding: 40px 16px;
  border: 2px dashed var(--blue-secondary);
  border-radius: 10px;
  height: 120px;
}
  .buffet_dishsizes_add_area > .fa-plus{
	color: var(--blue-secondary);
	height: 32px;
	width:32px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
  }
  .menu_list_button_area > div{
	display: none;
  }
  .buffet_dishsizes_add_area:hover .menu_list_button_area > div{
	display: block;
  }
  .buffet_dishsizes_add_area:hover {
	background-color: var(--secondry-blue);
  }
  .buffet_dishsizes_add_area:hover .fa-plus{
	display: none;
  }
  .buffet_dishsizes_add_area > .fa-plus{
	position: absolute;
	left: 50%;
	bottom: 35%;
  }
  .menu_list_add_button:hover{
	background-color: #E5F6FE;
	border: 2px dashed var(--blue-secondary);
  }
  .menu_list_add_button > .fa-plus{
	color: var(--blue-secondary);
	height: 32px;
	width:32px;
  }
  .menu_list_add_button:hover .fa-plus{
	transform: rotate(180deg);
	transition: all 0.3s ease-in-out;
	color: var(--blue-secondary);
}
.table_seprator{
	width: 90%;
	margin: 0 auto;
	border-top: 1px solid rgba(0, 0, 0, 0.3);
  }

  .tag-pills-svgs{
	width: 18px;
	height: 16px;
  }

  .single-order-detail {
	overflow-y: scroll;
  }