.user-image {
  height: 35px;
}

a.profilelink {
  color: rgba(36, 28, 21, 0.65);
  font-size: .90rem;
  text-decoration: none;

}

a.prim-link {
  color: rgba(36, 28, 21, 0.65) !important;
  transition: all 0.2s ease-in-out 0s !important;
  font-size: 1rem;
}

a.prim-link:hover {
  color: rgba(69, 83, 255, 1) !important;
}

a.prim-link.active {
  color: #4763fc !important;
  font-weight: bold;

}

.active {
  position: relative;
}

a.nav-link.active:after {
  content: '';
  position: absolute;
  top: 3.5rem;
  left: 0px;
  border-top: 3px solid #4763fc;
  width: 100%;
  cursor: auto;
}

a.profiledropdownitem {
  color: rgba(36, 28, 21, 0.65);
  font-size: 1rem;
  padding: 1rem !important;
  transition: all 0.2s ease-in-out 0s;

}

.profile-dropdown {
  margin-top: 8px;
  border-radius: 0 0 .25rem .25rem;
  z-index: 10000;
  line-height: 24px;
  padding: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3) !important;
  min-width: 275px;
}

a.profile-dropdown {
  color: rgba(36, 28, 21, 0.65);
  font-size: .90rem;
}

#autoThumbnailParent {
  width: 40px;
  height: 40px;
  border-radius: 32px;
  background: #f4f8fb;
}

#autoThumbnailParent::after {
  content: '';
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 50%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease-in-out;
  left: 0;
  top: 0;
}

#autoThumbnailParent:hover::after {
  opacity: 1;
}

#autoThumbnailChild {
  text-align: center;
  color: #4763fc;
  background-size: 32px 32px;
  line-height: 40px;
  font-size: 1rem;
  font-weight: bold;
}

/* Removes the dropdown arrow from the header memu */
.profilelink::after {
  display: none;
}

.profile-dropdown-header-item:hover {
  background-color: #fff !important;
}

.dropdown-divider {
  margin: 0;
}

.btn-primary-cmill {
  background-color: #4763fc;
  border-color: #4763fc;
  color: white;
  font-size: 15px;
  /*padding: 12px 16px !important;*/
  border-right: 4px;
  transition: all 80ms ease-in-out 0s;

}

.btn-primary-cmill:hover {
  color: white;
  background-color: rgb(10, 70, 228);
  border-color: rgb(10, 70, 228);
  transition: all 80ms ease-in-out 0s;

}

.noLinkEffect {
  text-decoration: none !important;
  color: unset !important;
}

.dropdown-shadow {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3) !important;
}

.vendor-user-and-restaurants {
  width: auto;
}

@media (max-width: 768px) {
  #chowmillNavbarPrim a.nav-link.active:after {
    top: 2.75rem;
  }

  .vendor-user-and-restaurants {
    width: 100%
  }

  .vendor-user-and-restaurants .dropdown-menu {
    position: absolute;
    left: auto !important;
    margin-right: 0 !important;
  }
}
.profile-dropdown-updated {
  display: block;
}
.meetings-bell {
  min-width: 110px;
}
.meetings-bell>a {
  margin-right: 0 !important;
}