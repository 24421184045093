/* td.fc-event-container {
	text-align: center;
} */

.cancelled-affect,
.cancelled-affect span {
  text-decoration: line-through
}

/* .meeting-spinner .spinner-border.spinner-wide-handling {
	margin-top: 25%;
	margin-bottom: 25%;
} */

/* .restaurant-spinner .spinner-border.spinner-wide-handling {
	margin-top: 25%;
} */

.order-spinner .spinner-border.spinner-wide-handling {
  margin-top: 30px;
}

/* .fc-ltr .fc-popover .fc-header .fc-title,
.fc-rtl .fc-popover .fc-header .fc-close {
	float: none !important;
} */

/* .fc-icon,
.fc-toolbar .fc-center {
	text-align: center;
	margin: 0 auto;
} */
/* 
td.fc-day-top a {
	float: right;
} */

.toast-bg-color {
  z-index: 999;
  position: fixed;
  top: 73px;
  right: 32px;
  min-width: 320px
}

/* 
.wizard>.content>.body {
	position: relative;
}

.next-wizard {
	float: right;
}

.previous-wizard {
	float: right;
} */
/* 
.day-of-week {
	text-transform: capitalize;
	display: inline-block;
}

.main-schedule {
	margin: 0 auto;
	max-width: 700px;
	min-height: 60px;
}

.schedule-plus {
	float: right;
}

.indvid-schedule {
	min-width: 100%;
	margin: 16px auto 0px;
}

.time-picker-elements {
	min-width: 100% !important;
}

.submit-buttom {
	margin: 0 auto;
	max-width: 270px;
}

.steper-buttons {
	max-width: 95%;
	min-height: 53px;
}

.background-primary-light {
	background: #e6e9ed;
	border: none;
	box-shadow: none;
	color: #000000;
}

.background-primary {
	background: #7571f9;
} */
/* 
.main-time-picker {
	width: 80%;
	margin-left: 18%;
	position: relative;
	top: -32px;
}

.download-url-links {
	text-decoration: none !important;
	font-weight: bold;
	float: right;
	margin-left: 10px;
} */

.status-modify-pill {
  display: block;
  max-width: 90px;
  margin-top: 5px;
  color: #ffffff;
}

/* .hours-of-operations {
	margin-top: 20px;
}

.black-color span {
	color: #000000 !important;
}

.main-wizard {
	border: 1px solid #f3f3f3;
	border-radius: 5px;
	margin-top: 55px;
}

.close-checked {
	width: 14%;
	line-height: 29px;
	margin-left: 14px;
}

.close-checked label {
	margin-right: 5px;
	font-size: 12px;
	font-weight: bold;
	display: inline-block;
} */

.callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
}

.callout-success {
  border-left-color: #5cb85c;
}

.callout-danger {
  border-left-color: #d9534f;
}

@media(max-width: 376px) {
  .toast-bg-color {
    right: 15px;
    min-width: 90vw
  }
}

@media screen and (max-width: 600px) {
  .order-history-range-picker .rdrInputRanges {
    display: none;
  }
}

/* ====== For Total Upcoming Order ==== */

.animated-chevron-up {
  transition: all .5s;
  transform: rotate(180deg);
}

.animated-chevron-down {
  transition: all .5s;
  transform: rotate(0deg);
}

.upcoming-order-container .upcoming-order-card {
  box-shadow: 0px 3px 16px #00000014;
  min-width: 200px;
  padding: 12px 0px;
  margin: 1.25rem 0;
  border-radius: 10px;
}

.upcoming-order-container .upcoming-order-card span>span {
  border-radius: 10px;
  line-height: 2;
}

.tableList:first-child {
  border-top: 2px solid #59A9FC;
}

.order-checkbox {
  text-align: start;
}

.table-data-padding {
  padding: 20px 0px 20px 27px !important;

}

.btn-hover:hover {
  background-color: #E2E6EA !important;
}

.upcoming-order-container .upcoming-order-card p:nth-child(2) {
  /* color: #a8a8a8; */
  margin-bottom: 0px;
}

.upcoming-order-container .upcoming-order-card .today-orders-total {
  width: 40%;
  background: #157FFC;
  color: white !important;
  margin: auto;
  border-radius: 10px;
  padding: 1px;
}

.upcoming-order-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 21px;
}
.upcoming-order-cards-title {
  font-size: 13px;
  font-weight: 600;
  color: #6E6E73;
  text-transform: uppercase;
  margin-bottom: 0;
}
.btn-orders {
  padding: 0.6rem;
}

/* ====== table data ====== */
table.order-history-records td {
  vertical-align: middle;
  font-size: 16px;
}

.order-history-records p {
  margin-bottom: 0px;
}

.order-history-records tbody>tr td p {
  color: #A8A8A8;
  font-size: 13px;
  margin-top: 5px;
}

.confirmed {
  padding: 5px;
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  cursor: default;
  border-radius: 16px;
  display: inline-block;
  border: none;
  min-width: 100px;
  text-align: center;
}

.heading {
  font-size: 26px
}

.date h4 {
  font-size: 25px;
  font-weight: 500;
}

.date button {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #212529;
  /* text-align: start !important; */
}

.order-history-range-picker .rdrMonths,
.order-history-range-picker .rdrDateRangePickerWrapper {
  flex-direction: row;
}

.order-history-range-picker .rdrDefinedRangesWrapper {
  width: 100%;
}

.rdrDateRangePickerWrapper .rdrDefinedRangesWrapper {
  width: 226px !important;
}

.rdrCalendarWrapper {
  width: auto !important;
}



/* Meeting Detail Page CSS */
.order-status {
  border-radius: 16px;
  padding: 5px 10px;
  color: white;
  font-size: 14px;
  font-weight: 600;
}

  .cancelled-order-btn {
    background-color: #DC3545 !important;
    border-radius: 5px;
    padding: 12px 20px;
    font-weight: 600;
    width: 119px;
    color: white !important;
  }
  
  .all-orders-cancelled {
    width: 175px !important;
    padding: 12px 15px !important;
  }
  
.accept-order-btn {
  background-color: #30A64A !important;
  border-radius: 5px;
  padding: 12px 20px;
  font-weight: 600;
  width: 119px;
  color: white !important;
}
.accept-order-btn:hover {
  background-color: #30A64A;
}
.accept-order-btn:focus-visible {
  background-color: #30A64A;
}
.accept-order-btn:active {
  background-color: #30A64A !important;
}

.accept-order-btn:hover {
  background-color: #28a143;
  border-radius: 5px;
  padding: 12px 20px;
  font-weight: 600;
  width: 119px;
  color: white !important;
}
.all-orders-accepted {
  width: 175px !important;
  padding: 12px 15px !important;

}
.btn-spinner {
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 1;
    border: 1px solid transparent;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.delivery-detail-section {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  background: white;
  box-shadow: 0px 3px 16px #00000014;
  border-radius: 10px;
}

.delivery-detail-section>div>h6:nth-child(1) {
  color: #212529;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 4px;
}

.delivery-detail-section>div>h6:nth-child(2) {
  color: #A8A8A8;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 4px;
}

.contact-chowmill {
  margin-top: 10px;
}

.contact-chowmill h5 {
  color: #646464;
  font-size: 16px;
  font-weight: 400;
}

.contact-chowmill h5 a {
  font-weight: 600;
  text-decoration: none;
}

.delivery-orders-detail-section {
  margin-top: 40px;
  margin-bottom: 20px;
}

.delivery-orders-detail-section .download-btns {
  display: flex;
  gap: 10px;
  align-items: center;
}

.delivery-orders-detail-section .download-btns button {
  color: #212529;
  font-weight: 400;
  font-size: 14px;
  background-color: white;
  font-size: 14px;
  font-weight: 600;
  padding: 12px;
  border: .75px solid #DDDFE1;
  text-align: start;
}

.delivery-prices-details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.delivery-prices-details>div {
  display: flex;
  justify-content: space-between;
  width: 300px;
  padding: 0 30px;
}

.delivery-prices-details>div>p:nth-child(1) {
  color: #A8A8A8;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.delivery-prices-details>div>p:nth-child(2) {
  color: #212529;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.delivery-prices-details>div>h4 {
  color: #212529;
  font-size: 22px;
  font-weight: 600;
}

/* //////// New Design Orders Page /////// */
.order-header {
  /* background-color:#1E4353; */
  /* width: 150%;
  height: 180px;
  position: relative;
  left:-25%; */
  position: absolute;
  width: 100%;
  padding: 0 15px;
  top: -160px;
  left: 0px;
}
.select-all-chkbox {
  padding: 0 15px;
}
.select-all-orders{
  padding-left: 55px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.order-cards-div::-webkit-scrollbar {
  display: none;
}
@media (min-width: 1190px) {
  .select-all-orders{
    padding-left: 70px;
  }
}
@media (max-width: 991px) {
  .order-header {
    top: -250px;
  }
  .order-cards-div{
    width: 130%;
  }
  .select-all-orders{
    padding-left: 27px;
  }
}
@media (max-width: 481px) {
  .order-header {
    top: -300px;
  }
  .order-cards-div{
    width: 110%;
  }
}

.order-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 15%; */
  /* padding-top: 36px; */
  /* position: absolute; */
  /* top: 30px; */
  /* text:white; */
  font-size: 26px;
  font-weight: 600;
}

.order-cards-div {
  overflow-x: scroll;
  /* margin-top: 18px;
  margin-left: 15%;
  width: 70%; */
  /* position: absolute;
  top: -80px;
  width: 50%; */
}

/* .order-cards {
  margin-left: 15%;
} */

/* .order-card-container {
  background-color: none;
  gap: 25px;
} */
.order-header-container {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-wrap: wrap;
  padding-top: 18px;
  padding-bottom: 12px;
  /* padding-top: 36px; */
  /* gap: 26%; */
}

.order-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  font-weight: 600;
  margin-left: auto;
}

.order-container-button {
  background-color: none;
  color: white;
  border: 1px solid white;
  min-width: 168px;
  border-radius: 5px;
  cursor: pointer;
}

.order-container-button:hover {
  background-color: white;
  color: #1E4353;
}
/* 
.order-container-button:focus-visible{
  background-color: none;
  border: 1px solid white;
} */

.order-container-calendar {
  background-color: none;
  color: white;
  border: 1px solid white;
  min-width: 168px;
}

.order-container-calendar:hover {
  background-color: white;
  color: #1E4353;
}

.order-history-heading {
  font-size: 26px;
  font-weight: 600;
}

.order-order-history {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: 22px;
}


.order-download-container {
  position: relative;
  /* border: 2px solid blue; */
  border-radius: 5px;
  /* background-color: red; */
  min-width: 170px;
}

.order-download-btn {
  border: 1px solid #DDDFE1;
  background-color: white;
  border-radius: 5px 0 0 5px;
  padding: 10px 48px 10px 12px;
  font-size: 16px;
  font-weight: 600;
}
.order-download-btn:hover {
  background-color: white;
  border: 1px solid #DDDFE1;
}
.order-download-btn-selected {
  background-color: #30A649 !important;
  color: white !important;
}
.order-accept-btn {
  position: absolute;
  right: 0;
  margin-top: 4%
}

.oder-download-arrow-btn {
  border: 1px solid #DDDFE1;
  border-left: none;
  border-radius: 0 5px 5px 0;
  background-color: white;
  padding: 11px 6px;
}

.oder-download-arrow-btn:hover {
  background-color: #EFF0F2;
}
.oder-download-arrow-btn:disabled {
  background-color: white !important;
  border: 1px solid #DDDFE1 !important;
  padding: 10px 6px !important;
}
.download-options-show {
  display: block;
  position: absolute;
  width: 100%;
}

.download-options-hide {
  display: none;
  position: absolute;
}

.download-options-list {
  background-color: white;
  /* border: 1px solid #DDDFE1; */
  font-size: 16px;
  cursor: pointer;
}

.download-options-list>p:hover {
  background-color: #EFF0F2;
}

.order-accept-parent {
  /* position: relative; */
  display: flex;
  width: 100%;
}

.order-accept-container {
  margin-right: auto;
}

.single-order-page {
  width: 100%;
  position: absolute;
  top: -180px;
}

.single-order-page-header {
  margin: 24px 0 10px 0;
  color: white;
  font-size: 26px;
  font-weight: 600;
}

.orders-breadcrumbs {
  font-size: 18px;
  color: white;
}

.single-order-table-container {
  border-radius: 10px;
  border: 1px solid #D9D9D9;
}

.single-order-table>thead>tr {
  background-color: white !important;
}

.single-order-table>thead>tr>th {
  color: black;
  text-transform: uppercase !important;
  font-size: 16px;
  font-weight: 600;
  min-width: 90px;
}

.order-restaurant-selector {
  box-shadow: 0px 3px 24px #00000014;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
}

.order-reataurant-names {
  font-size: 16px;
}

/* .restaurant-checkbox {
  height: 16px;
  width: 16px;
} */
.order-page-skeleton {
  position: absolute;
  top: -200px;
  width: 100%;
  overflow: scroll;
}
/* .single-order-page-skeleton {
  position: absolute;
  top: -250px;
} */
.order-date-heading {
  min-width: 85px;
}
.order-action-btns {
  display: flex;
  align-items: center;
  padding: 0;
}
@media (max-width: 576px){
  .order-action-btns {
    margin-top: 15px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 15px;
    flex-direction: column-reverse;
    /* align-items: center;
    padding: 0; */
  }
}
.text-wrapper {
  word-break: break-all;
}
/* .single-day-card {
  background-color: white;
  padding: 15px;
  border-radius: 17px;
} */
.single-order-skeleton {
  margin-top: -190px;
}