.brand-sign-up-logo img {
    margin-top: 0px !important;
    margin-bottom: 25px;
}

.invalid-feedback-password {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}