/* .email-popup-editor{
border: 1px solid #ccc
} */
#invoicesEmailPopup .react-multi-email {
  border: 1px solid #f3f3f3;
  cursor: text;
}

.email-popup-editor .quill {
  flex: 1;
}

.email-popup-editor .ql-container .ql-editor {
  min-height: 5rem;
  background-color: white;
}

.invoice-adjustment-datepicker {
  padding: 0 1.375rem;
  line-height: 32px;
  cursor: pointer;
}

.invoice-adjustment-datepicker input {
  border: none;
  width: 200px;
  cursor: pointer;
}

#edit-invoice-popup .ql-editor {
  min-height: 150px;
}

.h-40 {
  height: 40px;
}

#invoicesEmailPopup .email-footer {
  text-align: center;
  border-spacing: 0;
  border-collapse: collapse;
  font-family: proxima-nova, 'helvetica neue', helvetica, arial, geneva, sans-serif;
  width: 70%;
  border-top-style: solid;
  border-top-color: #ebeaef;
  color: #4e4e4e;
  font-size: 12px;
  margin: auto;
  padding: 0;
  border-width: 1px 0 0;
}

#invoicesEmailPopup .email-footer a {
  color: #dc3545;
  text-decoration: underline;
}

@media(max-width: 600px) {
  .wide-btn {
    width: 100%;
  }
}
.recipients-container {
  display: flex;
  flex-direction: column;
}
.recipients-container-field {
  display: flex;
  padding: 10px 0;
  border: 1px solid #f3f3f3;
  border-radius: 2px;
  flex-wrap: wrap;
}
.email-pill {
  line-height: 1;
  /* vertical-align: baseline; */
  margin: 0.14285714em;
  background-color: #f3f3f3;
  background-image: none;
  padding: 0.5833em 0.833em;
  color: rgba(0,0,0,.6);
  text-transform: none;
  font-weight: 600;
  border: 0 solid transparent;
  border-radius: 0.28571429rem;
  /* transition: background .1s ease; */
  font-size: .8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}
.email-button {
  cursor: pointer;
  margin-left: 0.8em;
}
.email-input {
  flex-grow: 2;
  border: none;
}
.email-input::placeholder {
  font-size: 13px;
  opacity: 0.5;
}