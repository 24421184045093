/* Menu Page Styling */
.menu-page {
  top: -180px;
  position: absolute;
  background-color: green !important;
}

/* Slot Machine Counter Styles */
.slot-machine-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.slot-machine-card {
  color: black;
  padding: 5px 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Number Display and Animations */
.number-display {
  font-size: 18px;
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}

.number-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.old-number,
.new-number {
  display: block;
  position: absolute;
  transition: opacity 0.5s ease;
}

.old-number {
  animation: slideUp 0.8s ease-out forwards;
}

.new-number {
  animation: slideDown 0.8s ease-out forwards;
}

@keyframes slideUp {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  50% {
    transform: translateY(-50%);
    opacity: 0.5;
  }

  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  50% {
    transform: translateY(50%);
    opacity: 0.5;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Difference Counter Animation */
.difference {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 14px;
  font-weight: bold;
  animation: differenceCounter 3s ease-out 2;
}

@keyframes differenceCounter {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  90% {
    transform: translateY(-50%);
    opacity: 0.8;
  }

  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

/* New Tag Styling */
.day-card-date {
  position: relative;
  overflow: hidden;
}

.new-tag {
  position: absolute;
  top: 60px;
  left: -15px;
  background-color: orange;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 3px 30px;
  border-radius: 3px;
  transform: rotate(-45deg);
  transform-origin: left top;
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.hidden {
  display: none;
}

.upcoming-date-card {
  position: relative;
}

.date-update-dot {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: red;
  font-size: 12px;
  font-weight: bold;
  padding: 5px;
  border-radius: 50%;
  transition: opacity 0.3s ease;
}